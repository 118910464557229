.MainContent {
  font-size: 2rem;
}

.MainContent a {
  color: #0000cd;
}

.MainClipboard {
  cursor: pointer;
}